var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _c(
        "div",
        { staticClass: "bg" },
        [
          _c(
            "v-card",
            {
              staticClass: "op ma-12 pa-4",
              attrs: { width: "500px", dark: "" },
            },
            [
              _c("v-card-title", { staticClass: "text-h1" }, [_vm._v("404")]),
              _c("v-card-subtitle", { staticClass: "text-h5 pa-8" }, [
                _vm._v(" Page does not exist "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { to: "/", "x-large": "" },
                    },
                    [_vm._v(" Home ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }